import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.26.1_next@14.2.20_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18._3f74fa6305863670532610e6b5b803b5/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.20_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.83.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.20_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.83.0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/shared/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FacebookPixelEvents"] */ "/app/src/components/smart/FacebookPixelEvents.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/providers/ClientProvider.tsx");
